import * as React from "react"
import { graphql } from "gatsby"
import Marquee from "react-fast-marquee"
import culturePageHero from'../images/hero-culture-page.jpg'

import Layout from "../components/layout"
import Seo from "../components/seo"

const CulturePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const talents = data.webiny.listTalent.data
  return (
    <Layout location={location} title={siteTitle} className="bg-black">
      
      <Seo title="Culture" />

      <div className="">
        <div className="h-120 md:h-[51.25rem] overflow-hidden relative lg:bg-none bg-cover bg-center" style={{ backgroundImage: `url(${culturePageHero})` }}>
          <img src={culturePageHero} className="hidden lg:inline-block w-full z-0" />
          <div className="px-5 bottom-5 lg:p-0 absolute lg:bottom-10 lg:left-5 z-30">
            <div className="w-full lg:pl-2">
              <h1 itemProp="headline" className="knockoutFont text-6xl md:text-8xl uppercase">
              OUR CREATORS AND TASTEMAKERS ARE THE CULTURE
              </h1>
            </div>
          </div>
          <div className="h-72 lg:h-96 bg-gradient-to-t from-black absolute w-full bottom-0 z-20" />
        </div>
      </div>

      <Marquee
        autoFill 
        pauseOnHover
        gradient={false} 
        className="w-full uppercase bg-gray-300 text-black TitlingGothicFBWideFont py-2 border-b-2 border-t-2 border-black">
          <div className="p-2">&nbsp;&nbsp;Friends of GWB from gaming, music, sport and TV.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//</div>
      </Marquee>

      <div className="bg-black text-center">
        <div className="">
          <div className="w-full">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-0">
              {talents.map(talent => {
                return (talent.visibility) ? 
                  <div key={talent.entryId} className="overflow-hidden relative">
                    <div className="after:h-screen after:bg-black after:hover:opacity-0 after:hover:transition-all after:hover:duration-500 after:opacity-40 after:absolute after:w-full after:left-0 bg-cover h-144 xl:h-152 md:w-full ease-in-out hover:scale-110 duration-500 transition bg-center border border-black" style={{ backgroundImage: `url(${talent.image})` }}>
                      <div className="uppercase text-white knockoutFont text-7xl flex px-5 items-left justify-bottom absolute z-20 bottom-10 left-5">{talent.name}</div>
                    </div>
                  </div>
                :
                  null
                })}
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default CulturePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      listTalent(sort: createdOn_ASC) {
        data {
          name
          description
          visibility
          image
          entryId
        }
      }
      listTalent(sort: createdOn_ASC) {
        data {
          name
          description
          visibility
          image
          entryId
        }
      }
    }
  }
`
